<template>
  <div
    class="day-legend"
    v-bind="$attrs"
    v-on="$listeners"
    tabindex="0"
    data-trigger="hover"
    :data-toggle="dataToogle"
    :title="dataTitle"
    :data-content="dataContent"
    data-placement="bottom"
  >
    <div class="badge badge-pil badge-secondary p-2">
      {{ badge }}
    </div>
    <span v-if="withTitle" class="ml-1">{{ $t("pages.planing-team.overtime.overtimeDay") }}</span>
  </div>
</template>

<script>
import { EmployeeOvertime } from "@/pages/components/data";

export default {
  props: {
    withTitle: {
      type: Boolean,
      default: true,
    },
    badge: {
      type: [String, Number],
      default: "",
    },
    overtime: {
      type: [EmployeeOvertime, Object],
      default: null,
    },
  },
  mounted() {
    $('[data-toggle="popover"]').popover();
  },
  methods: {},
  computed: {
    dataToogle() {
      return this.withTitle ? "" : "popover";
    },
    dataTitle() {
      return this.overtime?.processed ? this.$t("pages.planing-team.overtime.overtimeDay") : "";
    },
    dataContent() {
      return this.overtime?.processed
        ? this.$t("pages.planing-team.overtime.processedOverTimeAlert")
        : "";
    },
  },
};
</script>

<style></style>
